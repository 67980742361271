import Layout from "../components/layout"

import ShowConsultations from "../components/ShowConsultations"
import React from "react"

export default function Home() {
  return (
    <div>
      <Layout>
        <div className="flex align-center justify-center">
          <h1>404 Page not found.</h1>
        </div>
      </Layout>
    </div>
  )
}
